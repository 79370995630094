import useSidebars from '@/sidebars/hooks/useSidebars';

export default function useStoryEditorSidebars() {
  const sidebars = useSidebars();

  return {
    ...sidebars,
    replace: (replacementSidebar, sourceSidebarId) => {
      const wrappedReplacementSidebar = {
        ...replacementSidebar,
        props: {
          ...replacementSidebar.props,
          sidebarProps: {
            isShowBackdrop: false,
            size: 'sm',
            container: 'main',
            zIndex: 997, // It needs to appear below the story navigation bar
            ...replacementSidebar.sidebarProps,
          },
        },
      };

      return sidebars.replace(wrappedReplacementSidebar, sourceSidebarId);
    },
  };
}
